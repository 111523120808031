<!--
    @component: WEBMIQMAELEM
    @desc:      This component is used to render the slider with machine elements and additional informations in the WEBMULTI component.
    @author:    Yannick Herzog, info@hit-services.net
    @modified:  2019-07-09
 -->
<template>
  <div>
    <!-- Swiper -->
    <div class="swiper-container" v-loading="loadingSlider">
      <div class="swiper-scrollbar"></div>

      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="node in elements.GuiNodeCh"
          :key="node.Xnodekey"
          :class="[
            { active: selectedItem && selectedItem.Xnodekey === node.Xnodekey },
            node.MIQElementType,
            { hidden: node.AttNotValid === '2' },
            { valid: node.Valid && node.MIQElementType !== 'FIX' }
          ]"
        >
          <div class="swiper-slide-inner">
            <!-- Fixed Elements or optional and valid Elements -->
            <div
              v-if="isFixOrTRE(node.MIQElementType) || (!isFixOrTRE(node.MIQElementType) && node.Valid)"
              class="swiper-slide-inner__container"
              :class="[{ 'swiper-slide-inner__container--OPT': node.MIQElementType && !node.Valid }]"
            >
              <div class="swiper-slide-inner__text">{{ node.Lanqkey06 }}</div>

              <img
                v-if="node.ImageOnPathSelf"
                :src="getImageHandlerSrc(node.ImageOnPathSelf, { height: 420 })"
                :alt="node.Lanqkey06"
                class="swiper-slide-inner__image"
              />
              <img
                v-else-if="node.ImageOnPath"
                :src="getImageHandlerSrc(node.ImageOnPath, { height: 420 })"
                :alt="node.Lanqkey06"
                class="swiper-slide-inner__image"
              />
            </div>

            <!-- Optional Elements and not valid -->
            <button
              v-if="!isFixOrTRE(node.MIQElementType) && !node.Valid"
              class="swiper-slide-inner__btn swiper-slide-inner__btn--OPT"
              :title="node.Lanqkey01 | stripHtml"
              @click="onAddElementClick(node)"
            ></button>

            <!-- Buttons -->
            <div class="swiper-slide-inner_btn-group">
              <div v-if="isFixOrTRE(node.MIQElementType) || (!isFixOrTRE(node.MIQElementType) && node.Valid)">
                <!-- Info -->
                <el-button circle size="small" type="info" @click="onElementClick(node)" class="swiper-slide__btn-info">
                  <i class="fa fa-list"></i>
                </el-button>
              </div>

              <div v-if="!isFixOrTRE(node.MIQElementType)" class="m-t-half">
                <!-- Minus -->
                <span class="minus" v-if="node.Valid">
                  <el-button type="warning" circle icon="el-icon-minus" size="mini" @click="onRemoveElementClick(node)"></el-button>
                </span>
                <!-- Plus -->
                <span class="plus" v-if="!node.Valid">
                  <el-button
                    type="warning"
                    circle
                    icon="el-icon-plus"
                    size="mini"
                    :title="node.Lanqkey01 | stripHtml"
                    @click="onAddElementClick(node)"
                  ></el-button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Additional Informations -->
    <div v-if="selectedItem" class="m-t-2">
      <WEBMULTI :data="selectedItem" :update-node-key="elements.Xnodekey"></WEBMULTI>
    </div>
  </div>
</template>

<script>
import { find, forEach, get } from 'lodash';
import Swiper from 'swiper';
import WEBMULTI from './WEBMULTI';
import mixins from '@/shared/mixins/index';

export default {
  name: 'WEBMIQMAELEM',

  mixins: [mixins],

  components: {
    WEBMULTI
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      elements: {},
      isFirstRender: true,
      loadingSlider: false,
      selectedItem: null,
      swiper: null,
      swiperParameters: {
        freeMode: true,
        mousewheel: false,
        slidesPerView: 'auto',
        spaceBetween: 0,
        grabCursor: true,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
          hide: false
        },
        speed: 400
      }
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    handleLoadAdditionalData(node, loadFirst = true) {
      let loadNode = node;

      if (loadFirst) {
        loadNode = find(this.elements.GuiNodeCh, { Xreltyp: 'CLASS' });
      }

      this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: loadNode.Xnodekey });

      this.requestNode(loadNode.Xnodekey).then(result => {
        this.selectedItem = get(result, 'data.RESPONSE');
        this.$store.commit('pages/SET_PAGES_LOADING', { loading: false });
      });
    },

    handlePrevSiblings() {
      forEach(this.swiper.slides, slide => {
        if (!slide.className.includes('FIX') || !slide.className.includes('TRE')) {
          if (!slide.nextSibling) {
            return;
          }

          if (!slide.nextSibling.className.includes('FIX') || !slide.nextSibling.className.includes('TRE')) {
            // Wenn alle möglichen Optionen angezeigt werden
            if (slide.nextSibling.className.includes('valid')) {
              slide.className += ' remove-show-minus';
            } else {
              // Wenn ein Element hinzugefügt/entfernt werden kann
              slide.className = slide.className.split('remove-show-minus');
              slide.className += ' show-minus';
            }
          }
        }
      });
    },

    init() {
      //
    },

    initSwiper() {
      this.swiper = new Swiper('.swiper-container', this.swiperParameters);
    },

    isFixOrTRE(type) {
      return type === 'FIX' || type === 'TRE';
    },

    onElementClick(node) {
      this.handleLoadAdditionalData(node, false);
    },

    onAddElementClick(node) {
      this.requestSetMachineElement(node, false);
    },

    onRemoveElementClick(node) {
      this.requestSetMachineElement(node);
    },

    requestMachineElements() {
      this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: this.data.Xnodekey });

      this.$store
        .dispatch('webmiqmaelem/GET_MACHINE_ELEMENTS', {
          nodeKey: this.data.Xnodekey,
          nodeInput: ''
        })
        .then(response => {
          this.$store.commit('pages/HIDE_LOADING');
          this.elements = response.data.RESPONSE;

          // Transform properties
          forEach(this.elements.GuiNodeCh, child => {
            child.Valid = child.Valid === '0' ? false : true;
          });

          if (this.isFirstRender) {
            const firstNode = find(this.elements.GuiNodeCh, { Xreltyp: 'CLASS' });
            this.handleLoadAdditionalData(firstNode);
            this.isFirstRender = false;
          } else {
            this.handleLoadAdditionalData(this.selectedItem, false);
          }

          this.loadingSlider = true;
          setTimeout(() => {
            this.initSwiper();

            this.loadingSlider = false;
            this.handlePrevSiblings();
          }, 1500);

          // Update machine info on right side
          this.$store.dispatch('machineinfo/GET_MACHINE_INFO');
        })
        .catch(err => {
          this.$store.commit('pages/HIDE_LOADING');
          console.log('requestMachineElemets err', err);
        });
    },

    requestSetMachineElement(node, loadFirst = true) {
      // Replace the current node with data inside the component
      // so we can search for loading indicators for the clicked item
      this.$store.commit('SET_NODE', this.data);
      this.$store.commit('pages/SET_PAGES_LOADING', { loading: true, nodeKey: node.Xnodekey });

      this.$store
        .dispatch('webmiqmaelem/SET_MACHINE_ELEMENT', {
          nodeKey: node.Xnodekey,
          nodeInput: node.InputValue
        })
        .then(() => {
          this.$store.commit('pages/HIDE_LOADING');

          this.requestMachineElements();
          this.handleLoadAdditionalData(node, loadFirst);
        })
        .catch(err => {
          this.$store.commit('pages/HIDE_LOADING');
          console.log('requestSetMachineElement err', err);
        });
    }
  },

  watch: {
    data(newValue) {
      if (newValue.Xnodekey) {
        console.log('data changed, request machine elements', newValue);
        this.requestMachineElements();
      }
    }
  }
};
</script>

<style lang="scss">
.swiper-scrollbar {
  bottom: auto !important;
  top: 0;
}

.swiper-slide {
  width: auto !important;
  height: auto !important;

  background-color: #ffffff;
  border-bottom: 3px solid $c-gray;

  margin: 20px 0 5px;

  &.active {
    border: 3px solid $c-gray;
    border-bottom-width: 0;

    .swiper-slide__btn-info {
      background-color: $c-brand;
      border-color: $c-brand;

      i {
        color: #ffffff;
      }
    }
  }
}

.swiper-slide-inner {
  height: 100%;

  &__container {
    background-color: transparent;
    height: 100%;
  }

  &__btn {
    background-color: transparent;
    height: 100%;
    cursor: pointer;
    border: none;
    outline: none;

    margin: 5px 10px 0;

    &--OPT,
    &--TRK {
      background-color: lighten($c-light-gray, 5%);
      border-left: 5px solid white;
      border-right: 5px solid white;
      top: -5px;
      position: relative;
    }
  }

  &_btn-group {
    text-align: center;
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  &__text {
    font-size: 0.9rem;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  &__image {
    margin-top: 1.5rem;
  }
}

.swiper-slide__btn-info {
  background-color: #ffffff;
  border: 1px solid $c-black;
  transition: all 0.2s ease-in-out;
  transform: translateY(0%);

  i {
    color: $c-black;
  }

  &:hover {
    background-color: $c-black;

    i {
      color: #ffffff;
    }
  }
}

.swiper-slide {
  &.remove-show-minus {
    .minus {
      display: none !important;
    }
  }

  &.TRE {
    &.remove-show-minus {
      display: none !important;
    }
  }
}
</style>
